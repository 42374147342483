<template>
  <div class="form-input">
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
              dense
              outlined
              v-model="permission.name"
              :label="$t('permission.name')"
              :hint="`${server_errors.name}`"
              persistent-hint
              @keydown.enter="focusNext"
          >

          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-text-field
              dense
              outlined
              v-model="permission.display_name"
              :label="$t('permission.display_name')"
              :hint="`${server_errors.display_name}`"
              persistent-hint
              @keydown.enter="focusNext"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="12">
          <v-textarea
              @keydown.p.prevent.enter="ValidateForm"
              outlined
              v-model="permission.description"
              :label="$t('permission.description')"
              :hint="`${server_errors.description}`"
              persistent-hint
              dense>
          </v-textarea>
        </v-col>
        <v-col cols="12" md="12" lg="12">
          <v-btn class="btn-save-change" @click="ValidateForm">
            {{ $t("permission.saveChange") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

  </div>
</template>

<script>
export default {
  props: {
    permission: {},
  },
  data() {
    return {
      server_errors: {
        name: "",
        display_name: "",
        description: "",
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input,textarea'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    saveChange() {
      this.btnLoading = false;
      this.$axios.put(`admin/edit/permission`, {
        id: this.permission.id,
        name: this.permission.name,
        display_name: this.permission.display_name,
        description: this.permission.description
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$emit("close");
          this.$emit("success");
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style scoped lang="scss">
.form-input {
  width: 100%;
  height: 80vh;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 60px;

}
</style>
