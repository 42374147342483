<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("permission.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      placeholder="Search"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("permission.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listPermission.length > 0">
          <thead>
          <tr>
            <th class="text-start">
              {{ $t("permission.no") }}
            </th>
            <th class="text-left">
              {{ $t("permission.name") }}
            </th>
            <th class="text-left">
              {{ $t("permission.display_name") }}
            </th>
            <th class="text-left">
              {{ $t("permission.description") }}
            </th>
            <th class="text-left">
              {{ $t("permission.created") }}
            </th>

            <th class="text-left">
              {{ $t("permission.updated") }}
            </th>


          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listPermission" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              {{ item.name }}
            </td>

            <td>
              {{ item.display_name }}
            </td>
            <td>
              <div v-if="item.description">
                {{ item.description }}
              </div>
              <div v-else>-</div>
            </td>
            <td>
              {{ item.created_at }}
            </td>
            <td>
              {{ item.updated_at }}
            </td>
            <td class="text-start">


              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
<!--                  <v-list-item>-->
<!--                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>-->
<!--                  </v-list-item>-->
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchPermission"
        >
        </Pagination>

        <ModalEdit fullscreen title="Edit Permission">
          <template v-slot="{ close }">
            <EditPermission :permission="permission" @close="close" @success="fetchPermission()"/>
          </template>
        </ModalEdit>
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import EditPermission from "@/views/MainAdmin/Permission/CRUD/EditPermission";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    EditPermission
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listPermission: [],
      permission_id: "",
      permission: {}

    }
  },
  methods: {
    searchFilterItem() {
      this.fetchPermission();
    },

    filterPermission(permission_id) {
      return (
          this.listPermission.filter((item) => {
            return item.id == permission_id;
          })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({name: 'permission.create'}).catch(() => {
      });
    },

    onEdit(permission_id) {
      this.permission = {
        ...this.filterPermission(permission_id)
      };
      this.$store.commit("modalEdit_State", true);
    },

    // onDeleted(permission_id) {
    //   this.permission_id = permission_id
    //   this.$store.commit("modalDelete_State", true);
    // },

    fetchPermission() {
      this.isLoading = true;
      this.$axios.get(`admin/list/permissions`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listPermission = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listPermission.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      }).catch(error => {
        console.log(error)
        this.isLoading = false;
      })
    },
  },
  created() {
    this.fetchPermission();
  }
}
</script>

<style scoped lang="scss">
</style>
